import * as React from "react"

import Seo from '../components/seo'
import Layout from "../components/layout"

const IndexPage = () => (
  <>
    <Seo lang="en" title="Nested | Where you come to hide out" description="Nested offers escapes to tiny cabins nestled in nature. The cabins provide undistracted time in nature that allow our guests to slow down from their busy routines, disconnect from work and technology, reconnect with themselves and each other, and just be off."/>
    <Layout />
  </>
)

export default IndexPage